import React from "react";
import sedan from "../../assets/images/cars/sedan.png";
import etios from "../../assets/images/cars/etios.png";
import suv from "../../assets/images/cars/suv.png";
import innova from "../../assets/images/cars/ToyatoInnova.png";
import PricePlanComponents from "./PricePlanComponents";
const PricePlan = () => {
  return (
    <div className=" bg-zinc-200 py-5 md:py-8 lg:py-10 mx-auto px-4 md:px-8 lg:px-16" id="tarrif">
      <h4 className=" text-lg font-medium primary-color text-center">
        Pricing Plan
      </h4>
      <div className="text-3xl md:text-4xl font-bold mt-4 text-center">
        Our Most Valuable Drop Taxi Pricing
      </div>
      <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-3 mt-5 sm:mt-6 md:mt-8 lg:mt-10">
      <PricePlanComponents place="SEDAN" image={sedan} oneway="14" roundtrip="13" driverbeta="400" size="w-[400]"/>
        <PricePlanComponents place="ETIOS" image={etios} oneway="14" roundtrip="13" driverbeta="400" size="w-[400]"/>
        <PricePlanComponents place="SUV" image={suv} oneway="19" roundtrip="18" driverbeta="400" size="w-[200]"/>
        <PricePlanComponents place="INNOVA" image={innova} oneway="20" roundtrip="19" driverbeta="400" size="w-[400]"/>
      </div>
    </div>
  );
};

export default PricePlan;
