import React from 'react'
import Form from '../components/Form'
import { FaCheck } from 'react-icons/fa';
import { FaPhone } from "react-icons/fa6";
import airport from "../assets/images/airport transfer-covaicalltaxi.jpg";
import time from "../assets/images/24X7taxiservice.jpg";
import localrentals from "../assets/images/local rentals-covaicalltaxi.jpg";
import outstation from "../assets/images/outstation trips-covaicalltaxi.jpg"
import OurServiceComponent from "../components/Home/OurServiceComponent";
import vectorOneWayTaxi from "../assets/images/vectorCard1.jpg";
import airporttransfers from "../assets/images/vectorCard2.jpg";
import outstation3 from "../assets/images/VectorCard3.jpg";
import  hourlyrentals from "../assets/images/vectorCard4.jpg";
import Look from '../components/Look';
import Vision from '../components/Home/Vision';

const ServicePage = () => {
  return (
    <>
    <div className="form_bg relative bg-fixed flex justify-center px-3">
    <div className='bg-white w-full md:w-[80%] lg:w-1/2  rounded-lg px-4  pb-4 relative z-10 mt-4 md:mt-8 mb-4 md:mb-8' id='book_taxi_form'>
      <Form />
    </div>
  </div>
  <div className='container py-5 mx-auto px-4 md:px-8 lg:px-16'>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 py-14 gap-x-20 '>
                    <div className=' grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 w-full gap-3 items-center'>
                            < img src={localrentals} alt="about" className="" />
                            < img src={airport} alt="about" className="" />
                            < img src={time} alt="about" className="" />
                            < img src={outstation} alt="about" className="" />

                            {/* <div className=' absolute bottom-2 sm: bottom-[-70px] right-[100px] md:bottom-[10px] right-1 translate-x-1/2 md:translate-x-0 md:right-[60px]  bg-white py-4 px-4 rounded-xl shadow-md'>
                                <div className=' flex flex-col gap-y-1 items-center'>
                                    <span className='primary-color text-4xl font-bold'>6+</span>
                                    <span className=' text-lg opacity-60 font-medium'>Years Experience</span>
                                </div>

                            </div> */}
                    </div>
                    <div className='mt-10 md:mt-0 lg-mt-2 w-full '>
                        <div className='text-1xl md:text-3xl text-center font-medium mt-4 '>
Welcome to Zero Taksi
                        </div>

                        <div className="mt-5 lg:mt-10 flex flex-col grow">
                            <p className="">
                            Madurai Zero Taksi is your go-to call taxi service in Madurai, offering prompt, reliable, and affordable transportation solutions. Whether you need a ride across town, an outstation trip, or an airport transfer, our service is designed to cater to all your travel needs with utmost convenience.                        
                            </p>
                              <span className='flex gap-x-2  mt-3'> <FaCheck size={17} className='opacity-50'/> 24/7 Support</span>
                          <span className='flex gap-x-2  mt-3'> <FaCheck size={17} className='opacity-50'/> Best Price</span>
                          <span className='flex gap-x-2  mt-3'> <FaCheck size={17} className='opacity-50'/> Experienced Drivers</span>

                        </div>
                        <div className='flex  mt-5 gap-x-2 '>
                            <div>
                               <a href="#book_taxi_form" className='th-btn'>Book Now</a>
                            </div>
                         <div>
                             <a href="tel:+919344020689 " className='th-btn-reverse'>
                                   <span className='flex gap-x-2 items-center '><FaPhone size={17} /> +91 9344020689</span>
                              </a>
                         </div>
                      </div>

                    </div>

                </div>
                <div>
                    <h1 className="font-bold text-5xl text-center">Our Services</h1>
                <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-3 mt-5 sm:mt-6 md:mt-8 lg:mt-10">
        
        <OurServiceComponent image={vectorOneWayTaxi} heading="Local Rides" desc="Quick and reliable transportation within Madurai city"/>
        <OurServiceComponent image={airporttransfers} heading="Airport Transfers" desc="Timely pick-up and drop-off services to and from Madurai Airport."/>

        <OurServiceComponent image={outstation3} heading="Outstation Rides" desc="Comfortable and safe long-distance travel to various destinations."/>
        <OurServiceComponent image={hourlyrentals} heading="Hourly Rental" desc="Flexible hourly rental packages for shopping, business meetings, and city tours."/>

         </div>
         </div>
            </div>
            <Vision/>
            <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
          <div className="p-6">
        <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
        Why Choose Madurai Zero Taksi?                </h4>
        <div className='grid grid-cols-1 lg:grid-cols-2'>        
            <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">•  Safety and Comfort:</h3>
          <p>
          Our well-maintained vehicles and professional drivers ensure a safe and comfortable journey.          
          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">•	Affordable Pricing:</h3>
          <p>
          Transparent and competitive rates with no hidden charges.               </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">•	24/7 Availability:</h3>
          <p>
          Round-the-clock service to accommodate your travel plans anytime.              </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">•	Easy Booking:</h3>
          <p>
          Simple and fast booking process via phone call or our user-friendly website.             </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">•	Reliable Service:</h3>
          <p>
          Punctual and dependable service that you can trust.          </p>
        </div>

        </div>
        </div>

       <p>Experience hassle-free travel in Madurai with Madurai Zero Taksi Call Taxi Service. Book your ride today!</p>
      </div>

         <Look />

  </>
  )
}

export default ServicePage
