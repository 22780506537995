import React from 'react'
import { FaPhone } from "react-icons/fa";
import { IoMail } from "react-icons/io5";

const Contact = (props) => {
  return (
    // <div className="flex gap-x-5 items-center">
    // <div className='flex'> {props.isphone==1 ? <a href ={`tel:${props.contact}`} >{props.image} </a> :
    // <a href={`mailto:${props.contact}`} className=''>{props.image}</a> }    </div>


    // <div className='flex flex-column'>
    //   <span>{props.Label}</span>
    //   {props.isphone == 1 ? <a href={`tel:${props.contact}`} className="" >{props.contact}</a> :
    //     <a href={`mailto:${props.contact}`} className="">{props.contact}</a>}
    // </div></div>
    <div className='flex items-center gap-x-2 md:gap-x-5 lg:gap-x-5'>
      < span className=' w-[25px] h-[25px] md:w-[45px] md:h-[45px] lg:w-[45px] lg:h-[45px] rounded-full bg-[#FF0000] relative'>

      {props.isphone==1 ? < a href ={`tel:${props.contact} `} className='text-white' >{props.image} </a> :
      <a href={`mailto:${props.contact}`} className=' sm-hidden text-white'>{props.image}</a> }       
      </span>
      
        <span className="text-black  sm-hidden flex flex-col"> 
          <div className="hidden lg:block md:block text-black">{props.Label}</div>
             
           {props.isphone == 1 ? <a href={`tel:${props.contact}`} className="sm-text[12px] text-black" >{props.contact}</a> :
                <a href={`mailto:${props.contact}`} className="sm-hidden text-black">{props.contact}</a>}

        </span>
    </div>

)
}

export default Contact

