import React from 'react'
import Form from '../components/Form'
import TableComponent from '../components/TableComponent';
import Table from "../components/Table"

const TarrifPage = () => {
  const headings = ["Vehicles", "RENT Per day(Rs)", "FUEL Cost Per Km(Rs)", "Driver FOOD Charge"];
  const rowheadings=[
    { rowheading:"MINI",firstcol:"1500",secondcol:"10",thirdcol:"200"},
    { rowheading:"SEDAN",firstcol:"1600",secondcol:"11",thirdcol:"200" },
    { rowheading:"6 SEATER (ERTIGA,CARENS)",firstcol:"2000",secondcol:"11",thirdcol:"200"},
    { rowheading:"7 SEATER (XYLO,TAVERA)",firstcol:"2200",secondcol:"12",thirdcol:"200"},
    { rowheading:"7 SEATER (ONLY INNOVA)",firstcol:"2300",secondcol:"13",thirdcol:"200"},
    { rowheading:"CRYSTA INNOVA NEW 6 SEATER",firstcol:"2600",secondcol:"16",thirdcol:"200"},
    { rowheading:"TEMPO TRAVELLER 12 SEATER",firstcol:"2700",secondcol:"17",thirdcol:"200"},
    { rowheading:"TEMPO TRAVELLER 18 SEATER",firstcol:"3800",secondcol:"20",thirdcol:"200"},
    { rowheading:"15 SEATER",firstcol:"2700",secondcol:"17",thirdcol:"200"},
    { rowheading:"21 SEATER NON A/C COACH",firstcol:"3500",secondcol:"17",thirdcol:"200"},
    { rowheading:"21 SEATER A/C COACH",firstcol:"4500",secondcol:"25",thirdcol:"200"}
  ]
  const headings2 = ["Vehicles", "PER KM COST(Rs)", "DRIVER BETTA (Rs)", "Driver FOOD Charge"];

  const row2headings=[
    { rowheading:"MINI",firstcol:"12",secondcol:"400",thirdcol:"200"},
    { rowheading:"SEDAN",firstcol:"13",secondcol:"400",thirdcol:"200"},
    { rowheading:"6 SEATER (ERTIGA,CARENS)",firstcol:"16",secondcol:"400",thirdcol:"200"},
    { rowheading:"7 SEATER (XYLO,TAVERA)",firstcol:"17",secondcol:"400",thirdcol:"200"},
    { rowheading:"7 SEATER (ONLY INNOVA)",firstcol:"18",secondcol:"400",thirdcol:"200"},
    { rowheading:"CRYSTA INNOVA NEW 6 SEATER",firstcol:"21",secondcol:"400",thirdcol:"200"},
    { rowheading:"TEMPO TRAVELLER 12 SEATER A/C",firstcol:"22",secondcol:"500",thirdcol:"200"},
    { rowheading:"TEMPO TRAVELLER 18 SEATER A/C",firstcol:"29",secondcol:"500",thirdcol:"200"},
    { rowheading:"15 SEATER NON A/C",firstcol:"22",secondcol:"500",thirdcol:"200"},
    { rowheading:"21 SEATER NON A/C COACH",firstcol:"25",secondcol:"500",thirdcol:"200"},
    { rowheading:"21 SEATER A/C COACH",firstcol:"35",secondcol:"500",thirdcol:"200"}
  ]
const headings3=["HOUR RATE","INDICA","INDICA","INDICA","SWIFT/ETIOS","SWIFT/ETIOS","SWIFT/ETIOS","XYLO","XYLO","XYLO","INNOVA"];
const row3headings=[
  {hr:"BELOW 50 KM",I1:"Rs.80",I2:"Rs.80",I3:"Rs.80" ,S1:"Rs.80",S2:"Rs.80",S3:"Rs.80",X1:"Rs.100",X2:"Rs.100",X3:"Rs.100",In:"Rs.120"},
  {hr:"BELOW 100 KM",I1:"Rs.80",I2:"Rs.80",I3:"Rs.80" ,S1:"Rs.80",S2:"Rs.80",S3:"Rs.80",X1:"Rs.100",X2:"Rs.100",X3:"Rs.100",In:"Rs.120"},
  {hr:"ABOVE 100 KM",I1:"Rs.80",I2:"Rs.80",I3:"Rs.80" ,S1:"Rs.80",S2:"Rs.80",S3:"Rs.80",X1:"Rs.100",X2:"Rs.100",X3:"Rs.100",In:"Rs.120"}

];
const row4headings=[
  {v1:"MINI/ SEDAN",mk:"3 KM",r1:"Rs:100 /-",ar1:"Rs:22 /-",w1:"RS.2 /- PER MIN" ,nc:"25% Extra"},
  {v1:"XYLO",mk:"30 KM",r1:"Rs:800 /-",ar1:"Rs:36 /-",w1:"RS.2 /- PER MIN" ,nc:"25% Extra"},

];
const headings4=["VEHICLE","MINIMUM KM","RATE","ADDITIONAL PER KM","WAITING CHARGES","NIGHT CHARGE"];
  return (
    <>
    <div className="form_bg relative bg-fixed flex justify-center px-3">
    <div className='bg-white w-full md:w-[80%] lg:w-1/2  rounded-lg px-4  pb-4 relative z-10 mt-4 md:mt-8 mb-4 md:mb-8' id='book_taxi_form'>
      <Form />
    </div>
  </div>
  <div className='container mx-auto mt-5 px-5 relative bg-fixed flex flex-col justify-between items-center px-3 gap-3'>
  <h1 className="text-xl md:text-3x lg:text-4xl  font-semibold mt-10">CAB TARIFF <span className='text-[#EDB701]'> DAY RENT BASIC BELOW </span> 300 KM(PER DAY)</h1>
  <div className='mt-5 md:mt-8 lg:mt-10  overflow-y-auto w-full flex justify-center'>
  <TableComponent headings={headings} rowheadings={rowheadings}/> </div>
</div>
<div className='container mx-auto mt-22 px-5 relative bg-fixed flex flex-col justify-between items-center px-3 gap-3'>
  <h1 className=" text-xl md:text-3xl lg:text-4xl  font-semibold mt-10">CAB TARIFF <span className='text-[#EDB701]'> KM BASIC ABOVE </span> 300 KM(PER DAY)</h1>
  <div className=' mt-5 md:mt-8 lg:mt-10  overflow-y-auto w-full flex justify-center'>
    <TableComponent headings={headings2} rowheadings={row2headings}/> </div>
</div>

<div className='container mx-auto mt-16 px-5 relative bg-fixed flex flex-col justify-between items-center px-3 gap-3'>
  <h1 className=" text-xl md:text-3xl lg:text-4xl  font-semibold mt-10">CAB TARIFF <span className='text-[#EDB701]'> KM BASIC ABOVE </span> 300 KM(PER DAY)</h1>
  <div className=' mt-5 md:mt-8 lg:mt-10  overflow-y-auto w-full flex justify-center'>
    <Table />
  </div>
</div>
<div className='container mx-auto mt-16 px-5 relative bg-fixed flex flex-col justify-between items-center px-3 gap-3'>
  <h1 className=" text-xl md:text-2xl lg:text-2xl  font-semibold mt-10">ADDITIONAL CHARGES <span className='text-[#EDB701]'> PER KM  AFTER </span> FREE HOURS</h1>
  <div className='mt-5 md:mt-8 lg:mt-10 overflow-y-auto w-full'>
    <TableComponent headings={headings3} rowheadings={row3headings} />
  </div>
</div>
<div className='container mx-auto mt-22 px-5 relative bg-fixed flex flex-col justify-between items-center px-3 gap-3'>
  <h1 className=" text-xl md:text-3xl lg:text-4xl  font-semibold mt-10">LOCAL <span className='text-[#EDB701]'> TARRIF </span> </h1>
  <div className=' mt-5 md:mt-8 lg:mt-10  overflow-y-auto w-full flex justify-center'>
    <TableComponent headings={headings4} rowheadings={row4headings}/> </div>
</div>

</>
  )
}

export default TarrifPage
