import React from "react";
import BookingForm from "../components/BookingForm";
import About from "../components/About";
import PopularRoutes from "../components/PopularRoutes";
import Cars from "../components/Cars";
import Look from "../components/Look";
import OurServices from "../components/Home/OurServices";
import OneWayServices from "../components/Home/OneWayServices";
import TopRoutes from "../components/Home/TopRoutes";
import PricePlan from "../components/Home/PricePlan";
import Vision from "../components/Home/Vision";
import Package from "../components/Home/Package";
import ChooseUs from "../components/Home/ChooseUs";
import HeaderSlider from "../components/Home/HeaderSlider.js";

const Home = () => {
  return (
    <>
      {/* <HeaderSlider /> */}
      <BookingForm />
      <OurServices />
      <About />
      <Vision />
      <PricePlan />
      <PopularRoutes />
      <Package />
      <ChooseUs  />
      {/* <OneWayServices /> */}
      {/* <TopRoutes /> */}
      {/* <PopularRoutes />
      <Cars /> */}
      <Look />
    </>
  );
};

export default Home;
