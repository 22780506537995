import React from "react";
import banner from "../assets/images/Cover-Pic.gif";
import Form from "./Form";
import { FaPhone } from "react-icons/fa6";
import FormDuplicate from "./FormDuplicate";
import img1 from "../assets/images/ser1.png";
import img2 from "../assets/images/ser2.png";
import img3 from "../assets/images/ser3.png";
import img4 from "../assets/images/ser4.png";
import callicon from "../assets/images/callnow.gif"
import { Link } from 'react-router-dom';


const BookingForm = () => {
  const divStyle = {
    backgroundImage: `url( ${banner})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    backgroundColor:"none",
    // animation :`( ${animate} ) 1s linear infinite`  ,  
    
  };

  return (
    <>
    <div className=" form_bg relative bg-fixed animatedBackground"   >
      <div className="container  py-2 mx-auto  px-0 sm:px-4   md:px-8 md:py-24 lg:px-16 lg:py-24 relative z-10">
        <div className="grid grid-cols-1 lg:grid-cols-2  gap-y-6 gap-x-10 ">
          <div className="grid grid-cols-1">
          <div className="bg-white  rounded-lg px-4  pb-4 order-1  md:order-0 lg:order-0" id="book_taxi_form">

            <Form />
            {/* <FormDuplicate /> */}
          </div>
          </div>
          {/* <div className="order-0 md:order-1 lg:order-1">
            <img src={banner}/>
            </div> */}
           <div className="w-full md:w-[90%] lg:mt-20 mx-auto md:ml-auto px-4 sm:px-0">
            {/* <p className=" text-white font-semibold text-lg">
              Book Drop Taxi Service with Zero Taksi 
            </p> */}
                   <div className="text-md text-white">Zero Taksi</div>
                    <h3 className=" mt-4 font-bold text-black text-3xl md:text-5xl  lg:text-5xl ">
                    Book One Way Taxi
                    { " "}
        </h3>

            {/* <div className=" text-6xl text-white font-bold mt-4">
              Welcome to
            </div>
            <div className=" text-6xl text-white font-bold mt-4">
            Zero Taksi 

 
            </div> */}
            <div className="mt-4 text-lg text-white">
              {" "}
              We are providing one way taxi, outstation cabs, drop taxi and airport
          taxi service.
            </div>
            <div className=" text-white font-bold text-lg mt-10 ">
            <Link to="tel:+919344020689">  <img src={callicon} /> </Link>
            </div>
          </div> 
        </div>
      </div>
  

    </div>
    {/* <div>
    <div className="  px-0 sm:px-4 md:px-8 lg:px-16 bg-[#f5f5f5]  py-3 md:py-5 mt-4 md:mt-6 lg:mt-8">
        <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2  text-center">
          <div className=" flex flex-col gap-y-1">
            <img src={img1} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Drop Cabs
            </p>
            <p>OneWay Drop</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img2} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Outstation Cabs
            </p>
            <p>Outstation Oneway,Round Trip</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img3} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Round Trip
            </p>
            <p>Local & Outstation</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img4} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Tour Packages
            </p>
            <p>Tamilnadu,Kerala,Andhra,Pondicherry,Telangana</p>
          </div>
        </div>
      </div>

    </div> */}
    </>

  );
};

export default BookingForm;
