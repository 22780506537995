import React from "react";
import img1 from "../../assets/images/ser1.png";
import img2 from "../../assets/images/ser2.png";
import img3 from "../../assets/images/ser3.png";
import img4 from "../../assets/images/ser4.png";
import banner from "../../assets/cars/carBackground1.jpg";
import serach from "../../assets/images/search.png";
import star from "../../assets/images/star.png";
import flag from "../../assets/images/flag.png";
import taxi from "../../assets/images/taxiicon.png";
import aeroplane from "../../assets/images/aeroplaneicon.png";
import round from "../../assets/images/roundIcon.png";
import tourPackage from "../../assets/images/tour-guide-icon.png"
import OneWayServicesComponent from "./OneWayServicesComponent";
import OurServiceComponent from "./OurServiceComponent";
import vectorOneWayTaxi from "../../assets/images/vectorCard1.jpg";
import airporttransfers from "../../assets/images/vectorCard2.jpg";
import outstation from "../../assets/images/VectorCard3.jpg"
const OurServices = () => {
  const divStyle = {
    backgroundImage: `url( ${banner})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    backgroundColor:"none",
  };

  return (
    <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
        <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 mt-5 sm:mt-6 md:mt-8 lg:mt-10">
        <OurServiceComponent image={vectorOneWayTaxi} heading="One Way Taxi" desc="Perfect for when you need a ride to a destination but don't require a return trip."/>
        <OurServiceComponent image={outstation} heading="Outstation Taxi" desc="Hassle-free transfers to and from airports across Tamil Nadu."/>
        <OurServiceComponent image={airporttransfers} heading="Airport Taxi" desc="Plan your outstation trips with us and enjoy a smooth and enjoyable journey."/>

         </div>
    </div>
//     <div>
//       <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
//         <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
//         Our Service        
//         </h4>
//         <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-3 my-14">
//           <div className="flex flex-col bg-[#2A2F3C] rounded-lg p-4 px-10 justify-between">
//                <div className="flex justify-center">
//                 <img src={taxi} className="w-[60%]"/>
//                </div>
//             <span className="font-bold text-2xl text-white text-center">One-Way Taxi Service</span>
//             <p className="text-center text-white">Enjoy cost-effective one-way taxi services to various destinations across Tamil Nadu.</p>
//           </div>
//           <div className="flex flex-col bg-[#2A2F3C] rounded-lg p-4 px-10 justify-between">
//                <div className="flex justify-center">
//                 <img src={round} className="w-[60%]"/>
//                </div>
//             <span className="font-bold text-2xl text-white text-center">Round-Trip Taxi Service</span>
//             <p className="text-center text-white">We offer flexible scheduling to accommodate your travel plans for an extended journey.</p>
//           </div>
//           <div className="flex flex-col bg-[#2A2F3C] rounded-lg p-4 px-10 justify-between">
//                <div className="flex justify-center">
//                 <img src={tourPackage} className="w-[60%]"/>
//                </div>
//             <span className="font-bold text-2xl text-white text-center">Outstation Taxi Service</span>
//             <p className="text-center text-white">Tailor your travel itinerary to suit your preferences and enjoy a personalized travel experience.</p>
//           </div>
//           <div className="flex flex-col bg-[#2A2F3C] rounded-lg p-4 px-10 justify-between">
//                <div className="flex justify-center">
//                 <img src={aeroplane} className="w-[60%]"/>
//                </div>
//             <span className="font-bold text-2xl text-white text-center">Airport <br/> Transfers</span>
//             <p className="text-center text-white">Reliable airport transfer services to and from all major airports in Tamil Nadu.</p>
//           </div>

//         </div>
//         {/* <div className="flex flex-col gap-y-[10px] pt-4">
//           <h3 className="font-semibold text-3xl  sm:text-2xl">One-Way Taxi Service:</h3>
//           <p>
//           •	Affordable Rides: Enjoy cost-effective one-way taxi services to various destinations across Tamil Nadu.          </p>
//           <p>•	Wide Coverage: Our service covers all major cities and towns in Tamil Nadu, including Chennai, Coimbatore, Madurai, Trichy, Salem, and more.</p>
//         </div>
//         <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
//           <h3 className="font-semibold text-2xl">Round-Trip Taxi Service:</h3>
//           <p>
//           •	Convenient Round-Trips: Book our round-trip taxi service for a hassle-free travel experience.          </p>
//         <p>•	Flexible Scheduling: We offer flexible scheduling to accommodate your travel plans, whether it’s a short trip or an extended journey.</p>
//         </div>
//         <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
//           <h3 className="font-semibold text-2xl">Airport Transfers:</h3>
//           <p>
//           •	Timely Pickups and Drop-offs: Reliable airport transfer services to and from all major airports in Tamil Nadu.
//           </p>
//           <p>
//           •	Meet and Greet Service: Our drivers will meet you at the airport with a name sign and assist with your luggage.
//           </p>
//         </div>
//         <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
//           <h3 className="font-semibold text-2xl">Outstation Taxi Service:</h3>
//           <p>
         
//           •	Explore Tamil Nadu: Comfortable and safe outstation taxi services for exploring the beautiful landscapes and cultural heritage of Tamil Nadu.          </p>
//         <p>
//         •	Customizable Itineraries: Tailor your travel itinerary to suit your preferences and enjoy a personalized travel experience.
//         </p>
//         </div> */}



//       </div>
//       <div className="form_bg bg-fixed relative py-5 mx-auto px-4 md:px-8 lg:px-16" style={divStyle}>
//             <div className="container flex flex-col  relative z-[10] text-white justify-between gap-y-5">
//                 <h2 className=" text-3xl font-semibold text-center">See How It Work </h2>
//                 <p className=" text-center">We offers Outstation cabs and One way taxi at affordable and best price.</p>
//               <div className="p-4 grid grid-cols-1 py-10 sm:grid-cols-1 md:grid-cols-3 md-py-28 lg:grid-cols-3  lg-py-28 gap-x-5 place-items-center">
//                 <div className=" grid grid-col-4 mb-5 md:mb-0 lg:mb-0 w-full h-full border-solid border-2 border-white bg-transparent color-white flex flex-col gap-y-3 p-3 rounded-lg">
//                   <div >
//                   <img src={serach}/>
//                   </div>
//                   <div>Choose Your Location
//                   </div>
//                   <ol>
//                     <li>
//                     1.Enter your pickup & Drop Location
//                     </li>
//                     <li>
//                     2.Choose your Vehicle

// </li>
//                   </ol>
//                 </div>
//                 <div className=" grid grid-col-4 mb-5 md:mb-0 lg:mb-0 w-full h-full border-solid border-2 border-white bg-transparent color-white flex flex-col gap-y-3 p-3 rounded-lg">
//                   <div>
//                      <img src={star}/>
//                      </div>
//                      <div>Fill your Details
//                      </div>
//                   <ol>
//                     <li>
//                     1.Enter Your Name & Mobile
//                     </li>
//                     <li>
//                     2.Select your Travel Date

// </li>
//                   </ol>
//                 </div>
//                 <div className=" grid grid-col-4 mb-5 md:mb-0 w-full h-full lg:mb-0 border-solid border-2 border-white bg-transparent color-white flex flex-col gap-y-3 p-3 rounded-lg ">
//                  <div>
//                   <img src={flag}/>
//                   </div>
//                   <div>Booking Confirmation
//                   </div>
//                   <ol>
//                     <li>
//                     Just relax, your booking confirmation will sent your mobile number.<br/>
//                      Our representative will call you shortly.

// </li>
//                   </ol>
//                 </div>

//               </div>
//             </div>
//       </div>
//       <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
//         <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-1">
//           <div>
//         <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
//         Why Choose Us?
//                 </h4>
//         <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
//           <h3 className="font-semibold text-2xl">Experienced Drivers:</h3>
//           <p>
//           Our drivers are professional, experienced, and familiar with all routes in Tamil Nadu.
//           </p>
//           <p>

//           </p>
//         </div>
//         <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
//           <h3 className="font-semibold text-2xl">Well-Maintained Vehicles:</h3>
//           <p>
//           Our fleet includes a range of well-maintained vehicles to ensure your comfort and safety.
//           </p>
//         </div>
//         <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
//           <h3 className="font-semibold text-2xl">Affordable Pricing:</h3>
//           <p>
//           Competitive and transparent pricing with no hidden charges.          </p>
//         </div>
//         <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
//           <h3 className="font-semibold text-2xl">24/7 Availability:</h3>
//           <p>
//           We are available round the clock to meet your travel needs at any time.          </p>
//         </div>
//         </div>
//         <div>
//         <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
//         How It Works                </h4>
//         <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
//           <h3 className="font-semibold text-2xl">Book Your Ride:</h3>
//           <p>
//           Call us or use our online booking system to schedule your taxi service.          </p>
//           <p>

//           </p>
//         </div>
//         <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
//           <h3 className="font-semibold text-2xl">Confirm Details:</h3>
//           <p>
//           Receive a confirmation with driver details and estimated fare.          </p>
//         </div>
//         <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
//           <h3 className="font-semibold text-2xl">Enjoy Your Ride:</h3>
//           <p>
//           Our driver will arrive on time to pick you up and ensure a smooth journey to your destination.            </p>
//         </div>
//         <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
//           <h3 className="font-semibold text-2xl">Payment:</h3>
//           <p>
//           Pay securely via cash, card, or digital payment options after completing your ride.            </p>
//         </div>
//         </div>
//         </div>
//         <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
//           <h3 className="font-semibold text-2xl test-center">Customer Satisfaction:</h3>
//           <p>
//           Our top priority is your satisfaction. We strive to provide a seamless and enjoyable travel experience.          </p>
//         </div>

//       </div>
//       <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
//         <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
//         FAQs                </h4>
//         <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
//           <h3 className="font-semibold text-2xl">Q: How can I book a taxi?</h3>
//           <p>
//           A: You can book a taxi by calling our hotline or using our online booking system available on our website.             </p>
//           <p>

//           </p>
//         </div>
//         <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
//           <h3 className="font-semibold text-2xl">Q: Are your prices fixed or do they vary?</h3>
//           <p>
//           A: We offer competitive and transparent pricing with no hidden charges. The fare is based on the distance and type of service.          </p>
//         </div>
//         <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
//           <h3 className="font-semibold text-2xl">Q: Do you provide services for long-distance travel?</h3>
//           <p>
//           A: Yes, we offer both one-way and round-trip services for long-distance travel across Tamil Nadu.            </p>
//         </div>
//         <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
//           <h3 className="font-semibold text-2xl">Q: What payment methods do you accept?</h3>
//           <p>
//           A: We accept cash, credit/debit cards, and digital payments for your convenience.            </p>
//         </div>
//         <p>For reliable and convenient taxi services in Tamil Nadu, trust Zero Taksi 
//  Service. Book your ride today and experience the best in travel comfort and safety.</p>

//       </div>
//       {/* <div className="  px-0 sm:px-4 md:px-8 lg:px-16 bg-[#f5f5f5]  py-3 md:py-5 mt-4 md:mt-6 lg:mt-8">
//         <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2  text-center">
//           <div className=" flex flex-col gap-y-1">
//             <img src={img1} className=" w-[80px] mx-auto" />
//             <p className=" text-lg md:text-xl font-semibold">
//               Easy Online Booking
//             </p>
//             <p>Pickup and Drop on time</p>
//           </div>
//           <div className=" flex flex-col gap-y-1">
//             <img src={img2} className=" w-[80px] mx-auto" />
//             <p className=" text-lg md:text-xl font-semibold">
//               Professional Drivers
//             </p>
//             <p>Well Trained years of experience</p>
//           </div>
//           <div className=" flex flex-col gap-y-1">
//             <img src={img3} className=" w-[80px] mx-auto" />
//             <p className=" text-lg md:text-xl font-semibold">
//               Variety of Prime Brands
//             </p>
//             <p>Etios, Innova, & SUV</p>
//           </div>
//           <div className=" flex flex-col gap-y-1">
//             <img src={img4} className=" w-[80px] mx-auto" />
//             <p className=" text-lg md:text-xl font-semibold">
//               Secure Online Payment
//             </p>
//             <p>24 /7 Customer Support</p>
//           </div>
//         </div>
//       </div> */}
//     </div>
  );
};

export default OurServices;
