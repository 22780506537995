import React from 'react'

const TableComponent = ({headings,rowheadings}) => {
  return (
<div className="overflow-x-auto shadow-md sm:rounded-lg">
    <table className=" min-w-full  inline-block text-sm text-left rtl:text-right text-gray-900 ">
        <thead className="min-w-full text-xs text-gray-700 uppercase border-2 border-gray-500  ">
            <tr>
            {headings.map((heading, index) => (
              <th
                key={index}
                scope="col"
                className={`px-6 py-3  sm:text-md lg:text-lg text-bold ${
                  index % 2 === 0 ? "bg-yellow-200 dark:bg-gray-800 border-r-2 border-gray-500 " : "border-r-2 border-gray-500"
                }`}
              >
                {heading}
              </th>              
              )) }
            </tr>
        </thead>
        <tbody>
        {rowheadings.map((rowheading, rowIndex) => (
            <tr
              key={rowIndex}
              className="border-b border-2 border-gray-500 "              
            >
              {Object.entries(rowheading).map(([key, value], cellIndex) => (
                <td
                  key={cellIndex}
                  className={`px-5 py-3 border-r-2 border-gray-500 ${
                    cellIndex % 2 === 0 ? "bg-yellow-200 " : ""
                  } ${key === "name" ? "font-medium text-gray-900 whitespace-nowrap  " : ""}`}
                  scope={key === "name" ? "row" : ""}
                >
                  {value}
                </td>
              ))}
            </tr>
          ))}


                       {/* <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                    Apple MacBook Pro 17"
                </th>
                <td className="px-6 py-4">
                    Silver
                </td>
                <td className="px-6 py-4 bg-gray-50 dark:bg-gray-800">
                    Laptop
                </td>
                <td className="px-6 py-4">
                    $2999
                </td>
            </tr>
            <tr className="border-b border-gray-200 dark:border-gray-700">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                    Microsoft Surface Pro
                </th>
                <td className="px-6 py-4">
                    White
                </td>
                <td className="px-6 py-4 bg-gray-50 dark:bg-gray-800">
                    Laptop PC
                </td>
                <td className="px-6 py-4">
                    $1999
                </td>
            </tr>
            <tr className="border-b border-gray-200 dark:border-gray-700">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                    Magic Mouse 2
                </th>
                <td className="px-6 py-4">
                    Black
                </td>
                <td className="px-6 py-4 bg-gray-50 dark:bg-gray-800">
                    Accessories
                </td>
                <td className="px-6 py-4">
                    $99
                </td>
            </tr>
            <tr className="border-b border-gray-200 dark:border-gray-700">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                    Google Pixel Phone
                </th>
                <td className="px-6 py-4">
                    Gray
                </td>
                <td className="px-6 py-4 bg-gray-50 dark:bg-gray-800">
                    Phone
                </td>
                <td className="px-6 py-4">
                    $799
                </td>
            </tr>
            <tr>
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                    Apple Watch 5
                </th>
                <td className="px-6 py-4">
                    Red
                </td>
                <td className="px-6 py-4 bg-gray-50 dark:bg-gray-800">
                    Wearables
                </td>
                <td className="px-6 py-4">
                    $999
                </td>
            </tr> */}
        </tbody>
    </table>
</div>
  )
}

export default TableComponent
