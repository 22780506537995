import React from 'react';
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import { FreeMode, Pagination, Navigation, Autoplay } from 'swiper/modules';
import chennai from '../../assets/images/services/Chennai_scroll.jpg';
import coimbatore from '../../assets/images/services/Coimbatore _scroll.jpg';
import trichy from '../../assets/images/services/Trichy.jpg';
import banglore from '../../assets/images/services/Bangalore_scroll.jpg';
import pondicherry from '../../assets/images/services/Pondicherry.jpg';

const Package = () => {
  return (
    <div className='flex items-center justify-center flex-col '>
           <h1 className="text-cent font-bold text-5xl p-10">Our Packages</h1>
      <Swiper
        loop={true}         
           autoplay={{
          delay: 3000, 
          disableOnInteraction: false // Autoplay will not be disabled after user interactions
        }}
        breakpoints={{
          340: {
            slidesPerView: 1,
            spaceBetween: 15
          },
          700: {
            slidesPerView: 3,
            spaceBetween: 15
          }
        }}
        freeMode={true}
        pagination={{
          clickable: true
        }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }} 
        modules={[FreeMode, Pagination, Navigation, Autoplay]}
        className='max-w-[90%] lg:max-w-[80%] p-2 mt-3'
      >
        <SwiperSlide className='mb-20 '>
          <img src={chennai} alt="Chennai" />
          <p className="text-center font-semibold text-lg mt-3">Banglore to Chennai</p>
        </SwiperSlide>
        <SwiperSlide className='mb-20'>
          <img src={coimbatore} alt="Coimbatore" />
          <p className="text-center font-semibold text-lg mt-3">Chennai to Coimbatore</p>

        </SwiperSlide>
        <SwiperSlide className='mb-20'>
          <img src={trichy} alt="Trichy" />
          <p className="text-center font-semibold text-lg mt-3">Trichy to Coimbatore</p>

        </SwiperSlide>
        <SwiperSlide className='mb-20'>
          <img src={banglore} alt="Bangalore" />
          <p className="text-center font-semibold text-lg mt-3">Banglore to Coimbatore</p>

        </SwiperSlide>
        <SwiperSlide className='mb-20'>
          <img src={pondicherry} alt="Pondicherry" />
          <p className="text-center font-semibold text-lg mt-3">Pondicherry to Coimbatore</p>

        </SwiperSlide>

        {/* Navigation buttons */}
        {/* <div className="swiper-button-prev"></div>
        <div className="swiper-button-next"></div> */}
      </Swiper>
    </div>
  );
};

export default Package;
