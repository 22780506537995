import React from 'react'

const PricePlanComponents = ({place , image , oneway ,roundtrip,driverbeta,size}) => {
  return (
    <div className="bg-white py-6 px-4 rounded-lg shadow-lg border">
    <div className=' flex flex-col justify-between h-full'>
      <div className='flex justify-center'><img src={image} className=" w-1/2" /> </div>
          <div className=" flex flex-col gap-y-2  mt-3 pb-3 pl-5 ">
              <span className="flex gap-x-1 font-bold align-baseline ">
                  <span className=" primary-color text-4xl ">Rs</span>
                  <span className=" text-8xl ">{oneway}</span>
              </span>
          </div>
          {/* <div className=" flex flex-col gap-y-2 flex-1 items-center">
              <span className="flex gap-x-1 font-semibold align-baseline">
                  <span className=" primary-color text-xl">Rs</span>
                  <span className=" text-3xl">{roundtrip}</span>
              </span>
              <span className=" opacity-50 font-semibold">Round Trip</span>
          </div> */}

      <div className=" flex  flex-col gap-3 justify-between mt-2 pb-3 pl-5 border-l-2 border-red-500 border-solid  ml-20">
              <span className=" opacity-50 font-semibold">One Way</span>

              <p className=" opacity-85 text-4xl font-semibold">{place}</p>
              <span className="opacity-90 text-md ">Round Trip - {roundtrip} ₹</span>
              <span className="opacity-90 text-md ">One Way - Minimum 130 km</span>
              <span className="opacity-90 text-md ">Round Trip - Minimum 250 km</span>

              <div>
                 <div className="opacity-90 text-md ">  Driver Allowance - {driverbeta} ₹</div>
                 <div className="opacity-90 text-md mt-2" >( One Way Driver's allowance is Rs 600 for over 400 kms. )</div>
                 <div className="opacity-90 text-md mt-2" >( Round Trip Driver's allowance is Rs 600 for over 500 kms. )</div>
              </div>
              <div className="text-center text-semibold text-lg leading-6">Toll, Parking, State permit extra *</div>

              </div>
      <div className=" flex  mt-10 justify-center">
          <a  href="tel:+919344020689"  className="th-btn ">CHOOSE CAB</a>
      </div>
    </div>
  </div>
  )
}

export default PricePlanComponents
