import React from 'react'

const Table = () => {
  const headings = ["KM", "FREE HOURS", "INDICA NON AC", "INDICA AC", "SWIFT NON AC", "SWIFT AC", "XYLO NON AC", "XYLO AC", "INNOVA AC"];
const rowheadings = [
  
  {km:"20",fh:"2",INA:["11","420"],IAC:["12","440"],SNA:["12","440"],SA:["13","460"],XNA:"15",XA:null,IA:null },
  {km:"30",fh:"3",INA:["11","530"],IAC:["12","560"],SNA:["12","560"],SA:["13","590"],XNA:["15","770"],XA:["16","800"],IA:["18","860"] },
  {km:"40",fh:"4",INA:["11","640"],IAC:["12","680"],SNA:["12","680"],SA:["13","720"],XNA:["15","920"],XA:["16","960"],IA:["18","1040"] },
  {km:"50",fh:"4",INA:["11","750"],IAC:["12","800"],SNA:["12","800"],SA:["13","850"],XNA:["15","1070"],XA:["16","1120"],IA:["18","1220"] },
  {km:"60",fh:"4",INA:["11","860"],IAC:["12","920"],SNA:["12","920"],SA:["13","980"],XNA:["15","1220"],XA:["16","1280"],IA:["18","1400"] },
  {km:"70",fh:"4",INA:["11","970"],IAC:["12","1040"],SNA:["12","1040"],SA:["13","1110"],XNA:["15","1370"],XA:["16","1440"],IA:["18","1580"] },
  {km:"80",fh:"4",INA:["11","1080"],IAC:["12","1160"],SNA:["12","1160"],SA:["13","1240"],XNA:["15","1370"],XA:["16","1600"],IA:["18","1760"] },
  {km:"90",fh:"4",INA:["11","1190"],IAC:["12","1280"],SNA:["12","1280"],SA:["13","1370"],XNA:["15","1670"],XA:["16","1760"],IA:["18","1940"] },
  {km:"100",fh:"5",INA:["11","1300"],IAC:["12","1400"],SNA:["12","1400"],SA:["13","1500"],XNA:["15","1820"],XA:["16","1920"],IA:["18","2120"] },
  {km:"110",fh:"5",INA:["11","1410"],IAC:["12","1520"],SNA:["12","1520"],SA:["13","1630"],XNA:["15","1970"],XA:["16","2080"],IA:["18","2300"] },
  {km:"120",fh:"5",INA:["11","1520"],IAC:["12","1640"],SNA:["12","1640"],SA:["13","1760"],XNA:["15","2120"],XA:["16","2240"],IA:["18","2480"] },
  {km:"130",fh:"5",INA:["11","1630"],IAC:["12","1760"],SNA:["12","1760"],SA:["13","1890"],XNA:["15","2270"],XA:["16","2400"],IA:["18","2660"] },
  {km:"140",fh:"5",INA:["11","1740"],IAC:["12","1880"],SNA:["12","1880"],SA:["13","2020"],XNA:["15","2420"],XA:["16","2560"],IA:["18","2860"] },
  {km:"150",fh:"6",INA:["11","1850"],IAC:["12","2000"],SNA:["12","2000"],SA:["13","2150"],XNA:["15","2570"],XA:["16","2720"],IA:["18","3020"] },
  {km:"200",fh:"7",INA:["11","2400"],IAC:["12","2600"],SNA:["12","2600"],SA:["13","2800"],XNA:["15","3320"],XA:["16","3520"],IA:["18","3920"] },
  {km:"250",fh:"8",INA:["11","2950"],IAC:["12","3200"],SNA:["12","3200"],SA:["13","3450"],XNA:["15","4070"],XA:["16","4320"],IA:["18","4820"] },
  {km:"300",fh:"10",INA:["11","3500"],IAC:["12","3800"],SNA:["12","3800"],SA:["13","4100"],XNA:["15","4820"],XA:["16","5120"],IA:["18","5720"] },
  {km:"350",fh:"12",INA:["11","4050"],IAC:["12","4400"],SNA:["12","4400"],SA:["13","4750"],XNA:["15","5570"],XA:["16","5920"],IA:["18","6620"] },
  {km:"400",fh:"13",INA:["11","4600"],IAC:["12","5000"],SNA:["12","5000"],SA:["13","5400"],XNA:["15","6320"],XA:["16","6720"],IA:["18","7520"] },
  {km:"450",fh:"15",INA:["11","5150"],IAC:["12","5600"],SNA:["12","5600"],SA:["13","6050"],XNA:["15","7070"],XA:["16","7520"],IA:["18","8420"] },
  {km:"500",fh:"17",INA:["11","5700"],IAC:["12","6200"],SNA:["12","6200"],SA:["13","6700"],XNA:["15","7820"],XA:["16","8320"],IA:["18","9320"] },
  {km:"600",fh:"20",INA:["11","6800"],IAC:["12","7400"],SNA:["12","7400"],SA:["13","8000"],XNA:["15","9320"],XA:["16","9920"],IA:["18","11120"] },
  {km:"700",fh:"23",INA:["11","7900"],IAC:["12","8600"],SNA:["12","8600"],SA:["13","9300"],XNA:["15","10820"],XA:["16","11520"],IA:["18","12920"] },
  {km:"800",fh:"28",INA:["11","9000"],IAC:["12","9800"],SNA:["12","9800"],SA:["13","10600"],XNA:["15","12320"],XA:["16","13120"],IA:["18","14720"] },
  {km:"900",fh:"33",INA:["11","10100"],IAC:["12","11000"],SNA:["12","11000"],SA:["13","11900"],XNA:["15","13820"],XA:["16","14720"],IA:["18","16520"] },
  {km:"1000",fh:"38",INA:["11","11200"],IAC:["12","12200"],SNA:["12","12200"],SA:["13","13200"],XNA:["15","15320"],XA:["16","16320"],IA:["18","18320"] },

];

  return (


<div className="overflow-x-auto shadow-md sm:rounded-lg">
    <table className=" min-w-full  inline-block text-sm text-left rtl:text-right text-gray-900 ">
        <thead className="min-w-full text-xs text-gray-700 uppercase border-2 border-gray-500  ">
            <tr>
            {headings.map((heading, index) => (
              <th
                key={index}
                scope="col"
                className={`px-6 py-3  sm:text-xs lg:text-md text-bold ${
                  index % 2 === 0 ? "bg-yellow-200 dark:bg-gray-800 border-r-2 border-gray-500 " : "border-r-2 border-gray-500"
                }`}
              >
                {heading}
              </th>             
              )) }
            </tr>
        </thead>
        <tbody>
        {rowheadings.map((rowheading, rowIndex) => (
          <tr key={rowIndex} className="border-b border-2 border-gray-500">
            {Object.entries(rowheading).map(([key, value], cellIndex) => {
              const isSpecialColumn = key === "INA" || key === "IAC" || key === "IAC" || key === "SNA"|| key === "SA"|| key === "XNA" || key === "XA" || key === "IA";
              return (
                <td
                  key={cellIndex}
                  className={`px-5 py-3 border-r-2 border-gray-500 ${
                     cellIndex % 2 === 0 ? "bg-yellow-200" : ""
                  } ${key === "name" ? "font-medium text-gray-900 whitespace-nowrap" : ""}`}
                  scope={key === "name" ? "row" : ""}
                >
                  {Array.isArray(value) ? (
                    <div className="flex">
                         {value.map((val, index) => (
                      <tr key={index}>
    <td className={`flex-1 px-3 ${index === 1 ? 'pr-2' : 'border-r-2 border-gray-500'}`}> 
      {val}
    </td>
  </tr>
))}
                    </div>
                  ) : (
                    value
                  )}
                </td>
              ); 
            })}
          </tr>
        ))}
        

        </tbody>
    </table>
</div>

  )
}

export default Table

