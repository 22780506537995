import React, { useEffect, useState } from 'react'
import { MdLocationPin } from 'react-icons/md';
import { BsFillTelephoneFill } from 'react-icons/bs';
import logo from '../assets/images/zerotaksi-logo.png';
import { RxHamburgerMenu } from 'react-icons/rx';
import { AiFillCloseCircle } from 'react-icons/ai';
import NavList from './NavList';
import { Link } from 'react-router-dom';
import { FaPhone } from "react-icons/fa";
import { IoMail } from "react-icons/io5";

import Contact from './Contact';
const Header = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [openNav, setOpenNav] = useState(false)
    function openNavBat() {
        setOpenNav(true);
    }
    function closeNavBar() {
        setOpenNav(false);
    }

    useEffect(() => {
        // Define a function to handle scroll event
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        // Attach the event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Conditionally apply the class based on the state
    const headerClass = isScrolled ? 'header_parent header_scrolled' : 'header_parent';

    return (
        <div className={headerClass}>

        <div className='bg-[white]'>
            <div className=' h-[80px] bg-[black] md:h-[50px] lg:h-[50px] relative py-5 px-5 pb-6 flex flex-col  justify-center gap-y-5 lg:flex-row md:flex-row items-center md:justify-between lg:justify-between gap-x-5  mx-auto'>
             
                <a href="mailto:contact@zerotaksi.com" className='  text-white'>
                <span className='flex gap-2'><IoMail size={20}/>contact@zerotaksi.com</span></a>
                <a href="tel:+919344020689" className='  text-white'>
                <span className='flex gap-2'><FaPhone size={20} className="transform rotate-90"/> +91 93440 20689</span></a>

            
                {/* <div className=" ">
                     Zero Taksi 
 
                    <img src={logo} className='w-[50%]'/>
                </div> */}
                    {/* <img src={logo} className='w-[20%]'/>

                <div className='hidden lg:block md:block'>
                    <Contact image={<IoMail className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-[white] outline-black" />} Label={"Email Address :"} contact={"contact@zerotaksi.com"}  />

                </div>
                <div>

                    <Contact image={<FaPhone className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform rotate-90 text-[white]"  />} Label={"Phone Number :"} contact={"+91 93440 20689"} isphone='1' />
                </div> */}
                {/* <div className='hidden lg:block md:block '>
                    <a href="#book_taxi_form" className='th-btn-reverse '>Book Now</a>
                </div> */}


            </div>
            {/* <div className=' hidden lg:block md:block px-3 text-black bg-[#d1caca] absolute left-[50px] top-[120px]  right-[50px] z-[99]'>

                      <div className='relative '>
                                <div className='flex gap-x-5 justify-between items-center '>
                                    <div className='hidden font-medium  lg:inline-block w-[70%] pr-0 md:pr-10 lg:pr-16 xl:pr-20'>
                                      <NavList ul_class="text-black flex p-[15px]  gap-x-4 md:gap-x-6 lg:gap-x-5 xl:gap-x-8 lg:justify-between xl:justify-end text-lg sm:text-base  md:text-lg lg:text-xl text-white items-center nav_link_parent" li_class="text-black" />
                                     </div>

                                </div>   

                      </div>

          </div> */}
          < div className= { `flex py-5 justify-around`}>
        <Link to="/">  < img src={logo} alt="logo" className='w-[150px] ' /> </Link>
                              <div className='hidden font-medium  lg:flex lg:justify:center md:items-center  '>
                                 < NavList ul_class="flex gap-x-4 md:gap-x-6 lg:gap-x-5 xl:gap-x-8 lg:justify-between xl:justify-end text-lg sm:text-base  md:text-lg lg:text-xl text-black items-center nav_link_parent" li_class="" />
                            </div>
                           <div className='hidden lg:block md:block '>
                    <a href="#book_taxi_form" className='th-btn-reverse '>Book Now</a>
                </div>
                <div className='block lg:hidden cursor-pointer pr-8 text-white'>
                                        <span onClick={openNavBat} className="p-[15px]"><RxHamburgerMenu className=' text-[24px] md:text-[28px] text-black'/></span>
                                     </div>
                                     <div className={`fixed bg-white h-full w-[60%] top-0 left-0 z-50 transition-all ease-linear ${openNav ? '' : '-translate-x-[110%]'}`}>
                              <div className='px-4 py-3 bg-[#ffc9cb]'>
                              <Link to="/">    <img src={logo} alt="logo" className='w-[150px] mx-auto px-5' /> </Link>
                              </div>
                               <div className='py-6 px-4'>
                                 <NavList ul_class="font-semibold text-[14px] text-black flex flex-col gap-x-6 ml-6 uppercase gap-y-4" li_class="pt-3 border-b-2 pb-3" close_click={closeNavBar} />
                                 <span onClick={closeNavBar} className='absolute top-4 -right-3 cursor-pointer'><AiFillCloseCircle className='text-[25px] text-[#e30d16] ' /></span>
                               </div>
                            </div>


          </div>
          </div>
        </div>
        // <div className={headerClass}>
        //     <div >
        //         <div className='bg-[#5d5fef]'>
        //             <div className='container text-white mx-auto relative px-0 sm:px-4 md:px-8 lg:px-16 py-1 md:py-2 font-semibold'>
        //                 <marquee>Service starts @ Rs.14 per for details view our tariff</marquee>
        //             </div>
        //         </div>
        //         <div className=' bg-white'>
        //             <div className='relative bg-black'>
        //                 <div className='flex gap-x-5 justify-between items-center '>
        //                     <div className=' w-[65%]  md:w-[20%]  gap-x-5 '>
        //                     <Link to="/" className=' '>
        //                         <img src={logo} alt="logo" className=' w-[200px]  ' />
        //                         </Link>
        //                         </div> 
        //                     <div className='hidden font-medium  lg:inline-block w-[70%] pr-0 md:pr-10 lg:pr-16 xl:pr-20'>
        //                         <NavList ul_class="flex gap-x-4 md:gap-x-6 lg:gap-x-5 xl:gap-x-8 lg:justify-between xl:justify-end text-lg sm:text-base  md:text-lg lg:text-xl text-white items-center nav_link_parent" li_class="" />
        //                     </div>
        //                     <div className='block lg:hidden cursor-pointer pr-8 text-white'>
        //                         <span onClick={openNavBat} ><RxHamburgerMenu className=' text-[24px] md:text-[28px]'/></span>
        //                     </div>
        //                 </div>
        //                 <div className={`fixed bg-white h-full w-[60%] top-0 left-0 z-50 transition-all ease-linear ${openNav ? '' : '-translate-x-[110%]'}`}>
        //                     <div className='px-4 py-3 bg-[#ffc9cb]'>
        //                         <img src={logo} alt="logo" className='w-[150px] mx-auto px-5' />
        //                     </div>
        //                     <div className='py-6 px-4'>
        //                         <NavList ul_class="font-semibold text-[14px] flex flex-col gap-x-6 ml-6 uppercase gap-y-4" li_class="pt-3 border-b-2 pb-3" close_click={closeNavBar} />
        //                         <span onClick={closeNavBar} className='absolute top-4 -right-3 cursor-pointer'><AiFillCloseCircle className='text-[25px] text-[#e30d16] ' /></span>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export default Header